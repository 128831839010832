<template>

  <section id="organigrama-form">

    <div class="row">
      <div class="col-12">
        <h3 class="section-head-title ps-0 my-3">
            Completa los siguientes campos para publicar un organigrama.
        </h3>
      </div>
    </div>

    <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
      <div class="card-body d-flex flex-column mh-700">
        <div class="row flex-grow-1 mt-3">
          <div class="col-12 col-xl-6 d-flex flex-column mt-3 mt-lg-0">
            <h5 class="font-main text-secondary">Datos del documento</h5>
            <div class="card flex-grow-1">
              <div class="card-body">

                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">Añade un título</label>
                    <input 
                      type="text" 
                      class="form-control input-custom" 
                      maxlength="75"
                      v-model="org_title"
                      @blur="validateTitle"
                    />
                    <div class="form-text text-end">{{ org_title.length }} / 75</div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">Selecciona una empresa</label>
                    <v-select
                      class="selvue-custom mb-3"
                      :options="categorias"
                      :reduce="categoria => categoria.id_categoria"
                      label="nombre"
                      v-model="org_category"
                      :clearable="false"
                      >
                    </v-select>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="col-12 col-xl-6 d-flex flex-column mt-3 mt-lg-0">
            <h5 class="font-main text-secondary">
              Carga del documento
              <font-awesome-icon 
                  :icon="['fa-regular','circle-question']"
                  content="Aquí debes cargar un documento PDF"
                  v-tippy="{ arrow: true }"
                />
            </h5>
            <div class="card flex-grow-1">
              <div class="card-body">
                <div class="upload-image">
                  <label
                    v-if="!file_type"
                    class="upload-image-label upload-image-label-document"
                    for="input-file">
                    <span class="upload-image-label-icon">
                    </span>
                    <input 
                      type="file" 
                      id="input-file" 
                      @change="loadFiles($event)"
                      accept=".pdf"
                      style="display: none" 
                    />
                    <span class="upload-image-label-text">
                      <i class="fa-solid fa-arrow-up-from-bracket color-secondary"></i>
                      Haz clic aquí para seleccionar un documento
                    </span>
                  </label>
                  <div 
                    class="upload-image-preview upload-image-preview-document" 
                    v-if="file_type !== null"
                  >
                    <button 
                      @click="deleteDocument()"
                      class="upload-image-preview-remove"   
                    >
                      <font-awesome-icon icon="times"/>
                    </button>
                    {{
                      document_uploaded.name.length == 0
                        ? `Documento sin nombre`
                        : `${document_uploaded.name.substring(0, 30)}${
                            30 > document_uploaded.name.length ? "" : "..."
                          }`
                    }}
    
                    {{
                      Math.round(document_uploaded.size * 10) / 10 > 1
                        ? `${Math.round(document_uploaded.size * 10) / 10}Mb`
                        : ''
                    }}
                  </div>
                </div>
                <div class="alert alert-light border text-center p-2 mt-2">
                  <small>
                     
                    Peso máximo: 20MB, Formato: PDF
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2">
              <button 
                class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                @click="backList()"
              >
                Volver
              </button>
              <button
                v-if="editDocument == false"
                class="btn btn-custom-color-blue border-round-50 mw-100"
                @click="addOrganigrama()"
                :disabled="!form_complete"
              >
                Agregar
              </button>
              <button
                v-if="editDocument == true"
                class="btn btn-custom-color-blue border-round-50 mw-100"
                @click="editOrganigrama()"
                :disabled="!form_complete"
              >
                Guardar
              </button>
            </div>
          </div>
        </div> 

      </div>
    </div>
    <spinner v-show="show_spinner == true"/>
  </section>

</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import Spinner from "../Spinner.vue";

export default {
  props: {
    id: {
      type: String,
    },
  },
  components: { Spinner },
  data() {
    return {
      show_spinner: false,
      org_title: "",
      form_complete: false,
      document_upload: false,
      org_category: "0",
      check_title: false,
      check_company: false,
      editDocument: false,
      id_modulo:'17',
      id_company: this.$ls.get("user").empresa[0].id_empresa,
      id_user: this.$ls.get("user").id_usuario,
      nombre: "",
      file_type: null,
      document_uploaded: {
        pdf: "",
        pdf_prev: "",
        name: "",
        format: "",
        size: 0,
      },
    };
  },
  created() {
    this.getOrganigramaById(this.$route.params.id)  
  },
  mounted () {
    //console.log("ruta: ", this.$route.params);
    if (this.$route.params.id != undefined) {
      this.documento = this.getOrganigramaById(this.$route.params.id);
      console.log("Getter organigrama ID, ", this.documento);
      this.org_title = this.documento.nombre;
      this.org_category = this.documento.categoria[0].id_categoria;
      this.document_upload = true;
      this.editDocument = true;
      this.check_title = true;
      this.check_company = true;
      //his.form_complete = true;
      let get_format = this.documento.url_documento.slice(
          57,
          this.documento.url_documento.length
        );
      this.documento.format = get_format.split(".")[1];
      this.document_uploaded.pdf = this.documento.url_documento;
      this.document_uploaded.pdf_prev = this.documento.url_documento;
      this.document_uploaded.format = this.documento.format;
      this.document_uploaded.name =
      this.documento.nombre + "." + this.documento.format;
      this.file_type = "application";
    }
    this.getEmpresas();
  },
  watch: {
    document_upload(value) {
      if(value) {
        this.form_complete = true;
      }
      else {
        this.form_complete = false;
      }
    },
    check_title() {
      if (
        this.check_title == true &&
        this.check_company == true
      ) {
        this.form_complete = true;
      }
    },
    check_company() {
      if (
        this.check_title == true &&
        this.check_company == true
      ) {
        this.form_complete = true;
      }
    },
  },
  computed: {
    ...mapState("organigramaModule", ["categorias"]),
    ...mapGetters("organigramaModule", ["getOrganigramaById",]),
    // documento() {
    //   return this.getOrganigramaById(this.id);
    // },
  },
  methods: {
    ...mapActions("organigramaModule", ["editarOrganigrama", "getEmpresasCategoria", "crearOrganigrama"]),
    async getEmpresas(){
      let data_ = {
        id_empresa: this.id_company,
        id_modulo: this.id_modulo
      }
      console.log("data_ get categorias ", data_);
      await this.getEmpresasCategoria(data_);
    },
    loadFiles(event) {
      var files = event.target.files;
      var type = files[0].type.split("/")[0];
      var file_name = files[0].name;

      if (files.length > 0) {
        var reader = new FileReader();
        if (type != "application") {
          this.modal_status = false;
          this.open_modal_status = true;
          this.modal_status_msg = "Solo se aceptan archivos .pdf";
          this.modal_status = false;
          this.file_limit_mb = true;
        }
        if (type == "application") {
          if (files[0].size < 20000000) {
            //console.log("tamaño del archivo", files[0]);
            reader.onload = (e) => {
              var file_format = e.target.result.split(":")[1].split(";")[0];
              this.document_uploaded.pdf =
                e.target.result.split(",")[
                  e.target.result.split(",").length - 1
                ];
              this.document_uploaded.pdf_prev = e.target.result;
              this.document_uploaded.name = file_name;
              this.document_uploaded.format = file_format.split(";")[0];
              this.document_uploaded.size = files[0].size / 1000000;
              this.file_type = "application";
            };
            reader.readAsDataURL(files[0]);
          } else {
            this.open_modal_status = true;
            this.file_limit_mb = true;
            this.modal_status = false;
            this.modal_status_msg = "El documento excede los 5MB";
          }
          this.document_upload = true;
        }
      }
    },
    uploadDocument() {
      this.document_upload = true;
    },
    validateTitle() {
      if (this.org_title.length > 0) {
        this.check_title = true;
      } else {
        this.check_title = false;
      }
    },
    validateCompany() {
      if (this.org_category.length > 0) {
        this.check_company = true;
      } else {
        this.check_company = false;
      }
    },
    deleteDocument(){
      this.document_upload = false;
      this.document_uploaded = {
        pdf: "",
        pdf_prev: "",
        name: "",
        format: "",
        size: 0,
      };
      this.file_type = null;
    },
    async addOrganigrama() {
      //console.log("Hola estoy en agregar un organigrama");
      let file;
      let type_file;
      if (this.file_type == "application") {
          file = this.document_uploaded.pdf;
          type_file = this.document_uploaded.format;
      }
      let data_ = {
        nombre: this.org_title,
        archivo: file,
        formato_archivo: type_file,
        id_empresa: this.id_company,
        id_categoria: this.org_category,
        id_modulo: this.id_modulo,
        id_creador: this.$ls.get("user").id_usuario,
      }
      this.show_spinner = true;
      console.log("data de organigrama a cargar: ", data_);
      await this.crearOrganigrama(data_).then(res=>{
        console.log("data luego de crear documento: ", res);
        if(res){
            this.$toast.open({
            message: "Tu organigrama ha sido agregado correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
          this.$router.push({ name: "org-list" });
        }
        else{
            this.$toast.open({
            message: "Tu organigrama no se ha podido cargar, favor contactar al administrador",
            type: "error",
            duration: 0,
            position: "top-right",
          });
        }
        this.show_spinner = false;
      });
    },
    async editOrganigrama() {
      this.show_spinner = true;
      let file = "";
      let type_file = "";
      if (this.file_type == "application") {
          type_file = this.document_uploaded.format;
          file =
            this.documento.url_documento !== this.document_uploaded.pdf
              ? this.document_uploaded.pdf
              : ""; // si no se ha cambiado el documento se envia vacio
        }
      let data_ = {
        nombre: this.org_title,
        archivo: file,
        formato_archivo: type_file,
        id_empresa: this.id_company,
        id_categoria: this.org_category,
        id_modulo: this.id_modulo,
        id_modificador: this.$ls.get("user").id_usuario,
        id_documento: this.documento.id_documento
      }
      console.log("revisando datos: ", data_);
      await this.editarOrganigrama(data_).then(res => {
        this.show_spinner = false;
        if (res != null) {
          this.$toast.open({
            message: "Tu organigrama ha sido editado correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
          this.$router.push({ name: "org-list" });
        } else {
          this.$toast.open({
            message: "Ocurrio un problema al editar tu organigrama, intentalo nuevamente.",
            type: "error",
            duration: 0,
            position: "top-right",
          });
        }
      });
    },
    backList() {
      this.$router.push({ name: "org-list" });
    },
  },
};
</script>
